<template>
  <div id="personal">
    <page-top-header ref="topHeader">
      <div slot="top-header-left" class="top-header-left">
        <i class="iconfont guoran-a-14-14"></i>
        <span class="page-name">个人中心</span>
      </div>
    </page-top-header>
    <iframe class="personal-centent" :src="personalUrl"  frameborder="0" scrolling="auto"></iframe>
    <div class="mask" v-if="maskDialog"></div>
  </div>
</template>

<script>
import pageTopHeader from '../../components/page-top-header.vue';
export default {
  data(){
    return {
      maskDialog:false,
    }
  },
  components:{
    pageTopHeader
  },
  computed: {
    personalUrl() {
      if(this.isE()) {
        return "https://portal.ebot.isheely.com/" + '#/personalContent'
      } else {
        return process.env.VUE_APP_PERSONAL + '#/personalContent'
      }
    }
  },
  mounted(){
    window.addEventListener('message',(e) =>{
      if(e.data.data == 'MASK') {
        this.maskDialog = !this.maskDialog
      }
      if(e.data.data == 'reload') {
        this.$refs.topHeader.getUserInfo()
      }
    })
  }
}
</script>

<style lang="less" scoped>
#personal{
  width: 100%;
  height: 100%;
  overflow: hidden;
  // padding: 12px;
  // box-sizing: border-box;
  .personal-centent {
    width: calc(100% - 30px);
    height: calc(100% - 112px);
    margin: 15px;
    position: relative;
    z-index: 1000;
  }
  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    animation: dialog-fade-in 0.3s ease-in;
  }
}
</style>